import { buildGTADataLayerPurchase } from "@mappers/GoogleAnalytics/GoogleAnalytics.mapper";
import { OrderConfirmationDataModel } from "@dataModels/OrderConfirmation"
import { renderGetPlateStyleUrl } from "@utils/ApiUrls";
import Axios from "axios";
import { isValidGTM } from "@utils/Helpers";

const push = (data :any) =>{
    if(!isValidGTM()){
        console.log('data layer is not initialised.')
        return
    }

    window.dataLayer.push(data)
}

const trackEvent = (event:any,data:any) =>{
    if (isValidGTM()) {
        window.dataLayer.push({
            event: event,
            attributes:{
                'u4': data.combination,
                'u5': data.isAvailable,
                'u7': data.plateDesignId,
                'u8': data.plateForegroundColourId,
                'u9': data.captionTopText,
                'u10': data.captionBottomText,
                'u11': data.orderType,
                'u13': data.itemID,
                'u14': data.itemName,
                'u15': data.variant,
                'u18': data.restyleVSnewdesign,
            },
            // isReservationEnabled: Cookies.get('show-reservation') === "true",
            // isReservationButtonClicked: Cookies.get('click-reservation') === "true",
            // isNotificationButtonClicked: Cookies.get('click-notification') === "true"
        })
    }
}

const trackEventGA4 = (data: any) =>{
    // data.isReservationEnabled = Cookies.get('show-reservation') === "true",
    // data.isReservationButtonClicked = Cookies.get('click-reservation') === "true",
    // data.isNotificationButtonClicked = Cookies.get('click-notification') === "true"
    if (data && data.ecommerce && isValidGTM()){
        window.dataLayer.push({ecommerce: null });
    }
    push(data)
}

const getPlateDesignNameAndPlateStyleCode = async (apiUrl:string, frontPlateDesignId: number, frontForegroundColorId: number, backPlateDesignId: number, backForegroundColorId: number) => {
    const url = renderGetPlateStyleUrl(apiUrl, frontPlateDesignId, frontForegroundColorId, backPlateDesignId, backForegroundColorId);

    const result = await Axios.get(url)
    return result.data;
}
const pushGAPurchase =  (orderConfirmationData: OrderConfirmationDataModel) =>{
    const data = buildGTADataLayerPurchase(orderConfirmationData);
    push(data);
}

export const GAService = {
    push: push,
    trackEvent: trackEvent,
    trackEventGA4: trackEventGA4,
    pushGAPurchase: pushGAPurchase,
    // TODO: uncomment 
    getPlateDesignNameAndPlateStyleCode: getPlateDesignNameAndPlateStyleCode
}